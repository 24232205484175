// Importing SCSS Variables
@import "../sass/variables.scss";
// Importing SCSS Mixins
@import "../sass/mixin.scss";
// Importing Font Faces
@import "../sass/font.scss";



// SCSS Starts From Here
*{
    margin: 0px;
    padding: 0px;
}

// Font and Bg Colors

.col-font{
    color: $HeadingColor;
}

.col-pri{
color: $PrimaryColor;
}

.col-sec{
    color: $SecondaryColor;
}

.bg-pri{
    background-color: $PrimaryColor;
}

.bg-sec{
    background-color: $SecondaryColor;
}

// Font Families
.font-pri{
    font-family: $PrimaryFontFamily;
}

.font-pri-bold{
    font-family: $PrimaryBold;
}

.font-pri-extrabold{
    font-family: $PrimaryExtraBold;
}

.font-sec{
    font-family: $SecondaryFontFamily;   
}

// Font Weight
.fw-300{
    font-weight: 300;
    }
    
    .fw-400{
    font-weight: 400;
    }
    
    .fw-500{
    font-weight: 500;
    }
    
    .fw-600{
    font-weight: 600;
    }
    
    .fw-700{
    font-weight: 700;
    }
    
    .fw-800{
    font-weight: 800;
    }
    
    .fw-900{
    font-weight: 900;
    }

// Hover on Element (With White Color Before Hover)
.hover-pri{
    @include HoverWhiteBefore;
    &:hover{
        color: $PrimaryColor;
    }
}

.hover-sec{
    @include HoverWhiteBefore;
    &:hover{
        color: $SecondaryColor;
    }
}

.hover-font{
    @include HoverWhiteBefore;
    &:hover{
        color: $HeadingColor;
    }
}

// Hover on Element (With Heading Color Before Hover)
.hover-pri-head{
    @include HoverHeadBefore;
    &:hover{
        color: $PrimaryColor;
    }
}

.hover-sec-head{
    @include HoverHeadBefore;
    &:hover{
        color: $SecondaryColor;
    }
}

.hover-font-head{
    @include HoverHeadBefore;
    &:hover{
        color: $HeadingColor;
    }
}

// Font Sizes

.font-banner{
    font-size: $BannerFontSize;
}

.font-sub-banner{
    font-size: $BannerSubFontSize;
}

.font-heading{
    font-size: $HeadingFontSize;
}

.font-sub-heading{
    font-size: $SubHeadingFontSize;
}

.font-para{
    font-size: $ParaFontSize ;
}

.font-quote{
    font-size: $FontQuote;
    color: $SecondaryColor;
}

// Button Customization
.btn-customization{
    color: white;
    background-color: $ButtonbgColor;
    padding: $ButtonPadding;
    border-radius: $ButtonRadius;
    font-family: $PrimaryFontFamily;
    text-transform: $TextTransform;
    font-weight: $ButtonWeight;
    border: none;

    &:hover{
        background-color: $ButtonHoverColor;
    }
}

// Button Font Size
.smlr-btn{
    font-size: $SmallerButtonFont;
}

.sm-btn{
    font-size: $SmallButtonFont;
}

.md-btn{
    font-size: $MediumButtonFont;
}

// .lg-btn{
//     font-size: $LargeButtonFont;
// }

// .lgr-btn{
//     font-size: $LargerButtonFont;
// }

    // Header
    .header-logo{
        width: 110px;
    }
    // Navbar
    .navbar-nav{
        .nav-link{
            color: white !important;
            @include NavbarMenu;
    } 

    .dropdown-menu{
        @include NavbarMenu;
        display: none;
    }

    .right-menu{
       &:hover{
        .dropdown-menu{
            display: block;
        }
       } 
    }
    }
    // Footer
    .footer-logo{
        width: 210px;
    }

    .bg-footer{
        background:$FooterBg;
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;
        padding-top: 180px;
        padding-bottom: 40px;
    }
    .footer-heading{
        font-size: $FooterHeading;
    }

    .footer-text{
        font-size: $FooterParagraph;
    }

    .hr-heading{
        @include BorderPriHrOpacity;
    }

    .hr-footer{
        @include BorderPriHrOpacity;
        width: 78px;
    }

    // Home Banner Customization
    .home-banner-text{
        position: absolute;
        top: 28%;
        left: 3%;

        & h4{
            font-size: 38px ;
        }

        & h3{
            font-size: 48px ;
        }
    }

    .hr-center{
        @include BorderPriHrOpacity;
        width: 120px;
        margin-left: auto;
        margin-right: auto;
    }
    
     .insurance-logo img{
        width: 180px;
    }

    // Upper Heading Font
    .font-upper-heading{
        font-size: 16px;  
    }

    // Two Images Two Texts Section
    .section-img-1{
        background-position: unset;
        @include TwoImagesTwoTextsPosition(contain);
    }
    
    .section-img-2{
        background-position: right;
        @include TwoImagesTwoTextsPosition(contain);
    }

    .common-banner-text{
        position: absolute;
        top: 40%;
        left: 0%;
    }

    .ourvalues-banner{
        background-repeat: no-repeat;
        background-position: right;
        background-size: cover;
        height: max-content;
    }
    
// Media Screens

// Mobile
@media screen and (max-width: 575px){
            // Two Images Two Texts Section
            .section-img-1{
                background-position: unset;
                @include TwoImagesTwoTextsPosition(cover);
            }
    
            .section-img-2{
                background-position: unset;
                @include TwoImagesTwoTextsPosition(cover);
            }
    .font-banner{
        font-size: $MobileBannerFontSize;
    }
    
    .font-sub-banner{
        font-size: $MobileBannerSubFontSize;
    }
    
    .common-banner-text{
        position: absolute;
        top: 26%;
        left: 0%;
    }

    .footer-logo{
        width: 188px;
    }

    // Insurance Logo
    .insurance-logo img{
        width: 120px;
    }

    // Upper Heading Font
    .font-upper-heading{
        font-size: 13px;  
    }

    // Footer Background
    .bg-footer{
        background: #1d1f27 ;
        padding-top: 44px;
        padding-bottom: 44px;
    }

    // Font Sizes
    .font-heading{
        font-size: $HeadingFontSizeMobile;
    }
    
    .font-sub-heading{
        font-size: $SubHeadingFontSizeMobile;
    }
    
    .font-para{
        font-size: $ParaFontSizeMobile;
    }

    // Button Font Size
    .smlr-btn{
        font-size: $SmallerButtonFontMobile;
    }
    
    .sm-btn{
        font-size: $SmallButtonFontMobile;
    }
    
    .md-btn{
        font-size: $MediumButtonFontMobile;
    }
    // Home Banner Customization
    .home-banner-text{
        position: absolute;
        top: 14%;
        left: 4%;

        & h4{
            font-size: 14px ;
            line-height: 4px;
        }

        & h3{
            font-size: 22px ;
        }
    }
}
// Tablet
    @media screen and (min-width: 576px) and (max-width: 767px){
        .common-banner-text{
            position: absolute;
            top: 29%;
            left: 0%;
        }
        // Two Images Two Texts Section
        .section-img-1{
            background-position: unset;
            @include TwoImagesTwoTextsPosition(cover);
        }

        .section-img-2{
            background-position: unset;
            @include TwoImagesTwoTextsPosition(cover);
        }
            // Footer Background
    .bg-footer{
        background: #1d1f27 ;
        padding-top: 44px;
        padding-bottom: 44px;
    }

            // Home Banner Customization
    .home-banner-text{
        position: absolute;
        top: 17%;
        left: 4%;

        & h4{
            font-size: 20px ;
        }

        & h3{
            font-size: 30px ;
        }
    }
    
    // Button Font Size
    .sm-btn{
        font-size: $SmallButtonFontTablet;
    }
    
    .md-btn{
        font-size: $MediumButtonFontTablet;
    }
    }
    
    @media screen and (min-width: 768px) and (max-width: 991px){
    .common-banner-text{
            position: absolute;
            top: 35%;
            left: 0%;
        }
            // Two Images Two Texts Section
    .section-img-1{
        background-position: unset;
        @include TwoImagesTwoTextsPosition(cover);
    }
    
    .section-img-2{
        background-position: right;
        @include TwoImagesTwoTextsPosition(cover);
    }
            // Footer Background
    .bg-footer{
        background: #1d1f27 ;
        padding-top: 44px;
        padding-bottom: 44px;
    }

    // Home Banner Customization
    .home-banner-text{
        position: absolute;
        top: 21%;
        left: 4%;

        & h4{
            font-size: 30px ;
        }

        & h3{
            font-size: 40px ;
        }
    }
    
    // Button Font Size
    .sm-btn{
        font-size: $SmallButtonFont;
    }
    
    .md-btn{
        font-size: $MediumButtonFontTablet;
    }
    }

    @media screen and (min-width: 992px) and (max-width: 1199px){
        // Common Banner Font
        .font-banner{
            font-size: $TabletBannerFontSize;
        }
        
        .font-sub-banner{
            font-size: $TabletBannerSubFontSize;
        }

        .common-banner-text{
            position: absolute;
            top: 39%;
            left: 0%;
        }
        .home-banner-text{
            position: absolute;
            top: 27%;
            left: 4%;
    
            & h4{
                font-size: 30px ;
            }
    
            & h3{
                font-size: 40px ;
            }
        }
        
        // Button Font Size
        .sm-btn{
            font-size: $SmallButtonFont;
        }
        
        .md-btn{
            font-size: $MediumButtonFontTablet;
        }
        }

    // Insurance Logo
    .insurance-logo img{
        width: 120px;
    }