@mixin NavbarMenu {
    font-family: $PrimaryFontFamily;
    font-size: $NavbarFontSizeDesktop;
    font-weight: $NavbarFontWeight;
};

@mixin HoverWhiteBefore{
    text-decoration: none;
    color: white;
}

@mixin HoverHeadBefore{
    text-decoration: none;
    color: $HeadingColor;
}

@mixin BorderPriHrOpacity{
    border: $BorderHr ;
    opacity: 1.0;
}

@mixin TwoImagesTwoTextsPosition($size){
    background-size: $size;
    background-repeat: no-repeat;
    background-attachment: fixed;
}