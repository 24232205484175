@font-face {
    font-family: Primary-Font;
    src: url(../fonts/Montserrat/Montserrat-Regular.ttf);
}

@font-face {
    font-family: Secondary-Font;
    src: url(../fonts/Philosopher/Philosopher-Regular.ttf);
}

@font-face {
    font-family: Primary-Bold;
    src: url(../fonts/Montserrat/Montserrat-Bold.ttf);
}

@font-face {
    font-family: Primary-ExtraBold;
    src: url(../fonts/Montserrat/Montserrat-ExtraBold.ttf);
}